import React from 'react';
import { Link } from 'react-router-dom';
import './LandingPage.css';
import step1Image from './Assets/step1_4.png'; // Import your step images
import step2Image from './Assets/step2_2.png';
import step3Image from './Assets/step3_2.png';
import bluedove from './Assets/bluedovelogo.png';


const LandingPage = () => {
  return (
    <div className="landing-page">
            <header className="logo-header">
        <img 
          src={bluedove} 
          alt="Company Logo" 
          className="logo-image" 
        />
      </header>
      <div className="landing-header">
        <h1>Create Unique Custom T-Shirts with Ease</h1>
        {/* <p>Ready to turn your imagination into fashion?</p> */}
        <p>
        Use our AI-powered platform to quickly design t-shirts that reflect your ideas, creativity, and style. No hassle, just fun and creativity!
        </p>
      </div>

      <div className="how-it-works-container">
        <h2>How It Works</h2>
        <div className="how-it-works-box">
        {/* <h2>How It Works</h2> */}
          <div className="step">
            <h3>1. Create Your Design</h3>
            {/* <img src={step1Image} alt="Create Your Design" className="step-image" /> */}
            <div className="image-container">
              <img src={step1Image} alt="Create Your Design" className="step-image" />
            </div>
            <p>Simply describe your design, and AI will generate your design.</p>
          </div>
          <div className="step step2">
            <h3>2. Choose T-shirt Color and Size</h3>
            {/* <img src={step2Image} alt="Choose T-shirt Color and Size" className="step-image" /> */}
            <div className="image-container">
              <img src={step2Image} alt="Choose T-shirt Color and Size" className="step-image" />
            </div>
            <p>Then place your order online.</p>
          </div>
          <div className="step step3">
            <h3>3. We Deliver Your T-Shirts</h3>
            {/* <img src={step3Image} alt="We Deliver Your T-Shirts" className="step-image" /> */}
            <div className="image-container">
              <img src={step3Image} alt="We Deliver Your T-Shirts" className="step-image" />
            </div>
            <p>Get your custom T-shirts delivered right to your door.</p>
          </div>
          <div className="action-button">
            <Link to="/design">
              <button className="landing-button">
                Create Your Shirt!
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;

