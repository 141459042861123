// import React, {useState, useRef} from 'react';
import React, {useState, useEffect} from 'react';
import Select, { components } from 'react-select';
import './ImageGenerator.css';
import bluedove from '../Assets/bluedovelogo.png';
import background1 from '../Assets/background1.png';
import background2 from '../Assets/background2.png';
import background3 from '../Assets/background3.png';
import background4 from '../Assets/background4.png';
import axios from 'axios';
import whiteTshirt from '../Assets/tshirt_white.png';
import blackTshirt from '../Assets/tshirt_black.png';
import grayTshirt from '../Assets/tshirt_gray.png';
import blueTshirt from '../Assets/tshirt_blue.png';
import redTshirt from '../Assets/tshirt_red.png';

// Add your image assets for visuals (optional)
import sampleDesign1 from '../Assets/sampleDesign1.png'; // Replace with actual image paths
import sampleDesign2 from '../Assets/sampleDesign2.png';
import sampleDesign3 from '../Assets/sampleDesign3.png'; // Replace with actual image paths
import sampleDesign4 from '../Assets/sampleDesign4.png';
import sampleDesign5 from '../Assets/sampleDesign5.png';
import sampleDesign6 from '../Assets/sampleDesign6.png';
import sampleDesign7 from '../Assets/sampleDesign7.png';
import sampleDesign8 from '../Assets/sampleDesign8.png';

// 1. Define the array of font options outside the component
const fontOptions = [
  { value: 'Arial', label: 'Arial - Clean and Modern Sans-Serif', fontFamily: 'Arial, sans-serif' },
  // { value: 'Helvetica', label: 'Helvetica', fontFamily: 'Helvetica, sans-serif' },
  { value: 'Times New Roman', label: 'Times New Roman - classic and formal serif', fontFamily: '"Times New Roman", Times, serif' },
  // { value: 'Courier New', label: 'Courier New', fontFamily: '"Courier New", Courier, monospace' },
  // { value: 'Verdana', label: 'Verdana', fontFamily: 'Verdana, Geneva, sans-serif' },
  { value: 'Bebas Neue', label: 'Bebas Neue - all-caps and bold sans-serif', fontFamily: '"Bebas Neue", sans-serif' },
  { value: 'Montserrat', label: 'Montserrat - bold and contemporary sans-serif', fontFamily: 'Montserrat, sans-serif' },
  { value: 'Playfair Display', label: 'Playfair Display - high-contrast and stylish serif', fontFamily: '"Playfair Display", serif' },
  { value: 'Lobster', label: 'Lobster - decorative and bold cursive', fontFamily: 'Lobster, cursive' },
  // { value: 'Pacifico', label: 'Pacifico - casual and cursive', fontFamily: 'Pacifico, cursive' },
  // { value: 'Georgia', label: 'Georgia - elegant and traditional serif', fontFamily: 'Georgia, serif' },
  // { value: 'Roboto', label: 'Roboto', fontFamily: 'Roboto, sans-serif' },
  // { value: 'Open Sans', label: 'Open Sans', fontFamily: '"Open Sans", sans-serif' },
  // { value: 'Lato', label: 'Lato', fontFamily: 'Lato, sans-serif' },
  // { value: 'Raleway', label: 'Raleway', fontFamily: 'Raleway, sans-serif' },
  { value: 'Great Vibes', label: 'Great Vibes - flowing and elegant cursive', fontFamily: 'Great Vibes, cursive' },
  // { value: 'sans-serif', label: 'Sans-Serif', fontFamily: 'sans-serif' },
  // { value: 'serif', label: 'Serif', fontFamily: 'serif' },
  // { value: 'bauhaus', label: 'Bauhaus', fontFamily: 'sans-serif' }, // Using sans-serif as a placeholder
];

const fontStyleMapping = {
  'Arial': 'clean and modern sans-serif',
  // 'Helvetica': 'neutral and widely-used sans-serif',
  'Times New Roman': 'classic and formal serif',
  // 'Courier New': 'monospaced and typewriter-style',
  // 'Verdana': 'wide and highly-legible sans-serif',
  'Bebas Neue': 'all-caps and bold sans-serif',
  'Montserrat': 'bold and contemporary sans-serif',
  'Playfair Display': 'high-contrast and stylish serif',
  'Lobster': 'decorative and bold cursive',
  // 'Pacifico': 'casual and cursive',
  // 'Georgia': 'elegant and traditional serif',
  // 'Roboto': 'geometric and friendly sans-serif',
  // 'Open Sans': 'simple and open sans-serif',
  // 'Lato': 'rounded and warm sans-serif',
  // 'Raleway': 'elegant and thin sans-serif',
  'Great Vibes': 'flowing and elegant cursive',
  // 'sans-serif': 'a clean and modern sans-serif style',
  // 'serif': 'a classic and formal serif style',
  // 'bauhaus': 'a bold and geometric Bauhaus style',
};

// CustomOption.js (optional file) or within ImageGenerator.js

const CustomOption = (props) => {
  return (
    <components.Option {...props}>
      <div style={{ fontFamily: props.data.fontFamily }}>
        {props.label}
      </div>
    </components.Option>
  );
};

const usZipRegex = /^\d{5}(-\d{4})?$/;

const ImageGenerator = () => {

  const [image_url, setImage_url] = useState("/")
  const [inputValue, setInputValue] = useState(''); // State for input value
  const [loading, setLoading] = useState(false);
  const [bgLoading, setBgLoading] = useState(false); // Loading state for background removal
  const [generatedImage, setGeneratedImage] = useState(null);
  const [finalImage , setFinalImage] = useState(null); // State for final saved image
  const [tshirtColor, setTshirtColor] = useState(null); // Changed initial state to null
  const [page, setPage] = useState('initial'); // State to manage different pages
  const [history, setHistory] = useState([]); // Stack to keep track of page history
  const [imageType, setImageType] = useState('');
  const [subject, setSubject] = useState('');
  const [details, setDetails] = useState('');
  const [text, setText] = useState('');
  const [textposition, setTextPosition] = useState('');
  const [font, setFont] = useState('');
  const [fontColor, setFontColor] = useState('');
  const [backgroundColor, setbackgroundColor] = useState('');
  const [additional, setAdditional] = useState('');
  const [prompt, setPrompt] = useState('');
  const [feedback, setFeedback] = useState('');
  const [tshirtSize, setTshirtSize] = useState(null); // Initial state for size selection

  // Order form states
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [address, setAddress] = useState('');
  const [street, setStreet] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [zip, setZip] = useState('');

  const [errorMessage, setErrorMessage] = useState('');
  const [finalPrompt, setFinalPrompt] = useState('');
  const [orderId, setOrderId] = useState('');


  // const handleFontChange = (e) => {
  //   setFont(e.target.value);
  //   updatePrompt();
  // };
  const handleFontChange = (selectedOption) => {
    setFont(selectedOption ? selectedOption.value : '');
    updatePrompt(); 
  };
  

  // Combine all the values into a single prompt
  const updatePrompt = () => {
    let newPrompt = '';
    const fontStyle = fontStyleMapping[font] || 'default font style';
    if (page === 'hasText'){
      // newPrompt = `${imageType} of the text "${text}" written in ${font} typeface, centered in ${fontColor} on av${backgroundColor} background. ${additional}`.trim();
      newPrompt = `A typhography with the text "${text}", written in ${fontStyle} typeface, centered in ${fontColor} on a ${backgroundColor} background. ${additional}`.trim();
    } else if (page === 'hasImage'){
      // newPrompt = `${imageType} ${subject} ${details} on a ${backgroundColor} background. ${additional}`.trim();
      newPrompt = `An image of ${subject} ${details} on a ${backgroundColor} background. ${additional}`.trim();
    } else if (page === 'hasBoth'){
      // newPrompt = `${imageType} ${subject} ${details}, with the text "${text}" ${textposition} written in ${font} typeface, centered in ${fontColor} on ${backgroundColor} background. ${additional}`.trim();
      newPrompt = `An image of ${subject} ${details}, with the text "${text}" ${textposition}, written in ${fontStyle} typeface in ${fontColor} on ${backgroundColor} background. ${additional}`.trim();
    }
    setPrompt(newPrompt);
    // console.log('Generated Prompt:', newPrompt); 
  };

    // Use this effect to update the prompt whenever an input changes
  useEffect(() => {
      updatePrompt();
  }, [imageType, subject, text, textposition, font, fontColor, backgroundColor, additional, details]);

  useEffect(() => {
    if (page !== 'initial') {
        setHistory(prevHistory => [...prevHistory, page]);
    }
  }, [page]);

  console.log('Current path:', window.location.pathname);

  if (window.location.pathname === '/payment-success') {
    return (
      <div>
        <h1>Payment Successful!</h1>
        <p>Thank you for your order. Your payment has been processed successfully.</p>
      </div>
    );
  }


  const tshirtTemplates = {
    white: whiteTshirt,
    black: blackTshirt,
    gray: grayTshirt,
    blue: blueTshirt,
    red: redTshirt
  };

  const colorOptions = [
    { name: 'Red', color: 'red' },
    { name: 'Blue', color: 'blue' },
    { name: 'White', color: 'white' },
    { name: 'Gray', color: 'gray' },
    { name: 'Black', color: 'black' },
  ];

  // Array of size options
  const sizeOptions = ["XS", "S", "M", "L", "XL"];


  const goBack = () => {
      setHistory(prevHistory => {
          const updatedHistory = [...prevHistory];
          updatedHistory.pop(); 
          const lastPage = updatedHistory.pop(); 
          setPage(lastPage || 'initial'); 
          return updatedHistory;
      });
  };

  const generateImage = async () =>{
    if (inputValue === "" && prompt ==="") {
        return;
    }
    setLoading(true)

    const requestPrompt = page === 'describe' ? inputValue : prompt;
    if (!requestPrompt) {
      console.error('Prompt is empty');
      setLoading(false);
      return;
    }
    // console.log('Prompt being sent to the API:', prompt);
    
    try {
      const response = await fetch(
        "https://bluedovecreation-f12655b62b2c.herokuapp.com/api/generate-image",
        // "http://localhost:8000/api/generate-image",  //this is local testing
        {
            method: "POST",
            headers:{
                "Content-Type": "application/json",
            },
            body:JSON.stringify({
                prompt: requestPrompt,  // Use the `prompt` state directly here
                model: 'V_2_TURBO',
            }),
        }
      );
      if (!response.ok) {
      throw new Error(`Error: ${response.status} ${response.statusText}`);
    }
    const data = await response.json();

    // Verify the structure of the response
    if (!data.data || data.data.length === 0) {
      throw new Error("Invalid response structure from generate-image API");
    }
    // console.log('Generated image data:', data); // Log the response data
    const generatedPrompt = data.data[0].prompt;
    const imageUrl = data.data[0].url;

    console.log('Generated Prompt:', generatedPrompt); // Log the generated prompt
    console.log('Generated Image URL:', imageUrl); // Log the image URL

    setGeneratedImage(imageUrl);
    setImage_url(imageUrl);
    setPrompt(generatedPrompt); // Store the original prompt
    setInputValue(generatedPrompt); // Optionally, update inputValue with the generated prompt
    } catch (error){
    console.error('Error generating image:', error);
    } finally {
    setLoading(false)
    }
  }

  const removeBackground = async () => {
    if (!image_url || image_url === "/") {
      alert("No image to process.");
      return;
    }
    setBgLoading(true);
    try {
      // Send request to remove-background API
      const removeBgResponse = await fetch(
        "https://bluedovecreation-f12655b62b2c.herokuapp.com/api/remove-background",
        // 'http://localhost:8000/api/remove-background',
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ imageUrl: image_url }), // Send the original image URL
        }
      );
  
      if (!removeBgResponse.ok) {
        const errorData = await removeBgResponse.json();
        throw new Error(`Error removing background: ${errorData.detail}`);
      }
  
      const removeBgData = await removeBgResponse.json();
  
      if (!removeBgData.transparentImage) {
        throw new Error("Background removal failed.");
      }
  
      const transparentImageUrl = removeBgData.transparentImage;
  
      console.log('Transparent Image URL:', transparentImageUrl);
  
      // setTransparentImage(transparentImageUrl); // Set the transparent image URL
    // Replace the original image with the background-removed image
    setImage_url(transparentImageUrl);

  
    } catch (error) {
      console.error("Error removing background:", error);
      alert(`Error removing background: ${error.message}`);
    } finally {
      setBgLoading(false);
    }
  }
  
  const saveImage = async () => {
      // if (!generatedImage) return;
      if (!image_url || image_url === "/") {
        alert("No image selected.");
        return;
      }
      try {
      // setFinalImage(generatedImage); // Store the final image URL
      setFinalImage(image_url); // Use the current image_url (background removed or original)
      setFinalPrompt(prompt);
      setPage('selectColor'); // Navigate to the color selection page
      } catch (error) {
        console.error('Error saving the image:', error);
      }
  };

  const handleFeedback = async () => {
    if (!feedback) {
      alert('Please describe what you do not like about the image.');
      return;
    }   

    setLoading(true);

    try {
      const response = await fetch(
        'https://bluedovecreation-f12655b62b2c.herokuapp.com/api/refine-prompt', // this is heroku
        // 'http://localhost:8000/api/refine-prompt',  //this is local testing
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            originalPrompt: prompt,
            feedback: feedback,
          }),
        }
      ); 
      if (!response.ok) {
        throw new Error(`Error: ${response.status} ${response.statusText}`);
      }    
      const data = await response.json();
      console.log('Refined Prompt data:', data); // Log the response data
      console.log(data.newPrompt); // Log the response data
      const newPrompt = data.newPrompt;    
      setPrompt(newPrompt);
      setInputValue(newPrompt);     
      // Now generate the image with the new prompt
      await generateImageWithNewPrompt(newPrompt);
    } catch (error) {
      console.error('Error refining prompt:', error);
    } finally {
      setLoading(false);
      setFeedback(''); // Clear the feedback
    }
  };
    
      // Helper function to generate image with the new prompt
  const generateImageWithNewPrompt = async (newPrompt) => {
    if (!newPrompt) {
      return;
    }
    setLoading(true);
    try {
      const response = await fetch(
        'https://bluedovecreation-f12655b62b2c.herokuapp.com/api/generate-image', // this is heroku
      //  'http://localhost:8000/api/generate-image',  //this is local testing
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
              prompt: newPrompt,
              model: 'V_2_TURBO',
          }),
        }
      );
  
      if (!response.ok) {
        throw new Error(`Error: ${response.status} ${response.statusText}`);
      }
  
      const data = await response.json();
      const imageUrl = data.data[0].url;
  
      setGeneratedImage(imageUrl);
      setImage_url(imageUrl);
    } catch (error) {
      console.error('Error generating image:', error);
    } finally {
      setLoading(false);
    }
  };
      
  const orderTshirt = () => {
    if (!tshirtSize) {
      alert("Please select a size before placing an order.");
      return;
    }
    // Navigate to the order form page
    setPage('orderForm');
  };

  // New function to handle checkout after submitting the order
  const startCheckout = async (oid) => {
    // In a real scenario, dynamically set these line items based on product, size, etc.
    // Here we assume you have a Stripe Price ID or an item you want to charge for.
    // Replace "price_12345" with your actual price ID from Stripe.
    const lineItems = [
      { price: "price_1QV5UiHbIcYH0bIazTRP7cq9", quantity: 1, orderId: oid }, 
    ];
    try {
      const response = await fetch(
         'https://bluedovecreation-f12655b62b2c.herokuapp.com/api/create-checkout-session', // this is heroku
        //  'http://localhost:8000/api/create-checkout-session', 
      {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({ lineItems })
      });

      if (!response.ok) {
        throw new Error(`Error creating checkout session: ${response.status} ${response.statusText}`);
      }

      const data = await response.json();
      if (data.url) {
        // Redirect to Stripe Checkout
        window.location.href = data.url;
      } else {
        alert("Failed to create checkout session.");
      }
    } catch (error) {
      console.error("Error starting checkout:", error);
    }
  }

  const submitOrder = async () => {
    // Check for empty fields
    if (!name || !email || !phone || !street || !city || !state || !zip) {
      setErrorMessage("Please fill in all required fields.");
      return;
    }
    if (!usZipRegex.test(zip)) {
      setErrorMessage("Please enter a valid US ZIP code.");
      return;
    }
    const fullAddress = `${street}, ${city}, ${state}, ${zip}`;


    setErrorMessage(''); // Clear any previous error message
    // Add order submission logic here
    const orderDetails = {
      name,
      email,
      phone,
      address: fullAddress, // or keep them separate in DB
      tshirtColor,
      tshirtSize,
      finalImage,
      finalPrompt
    };

    try {
      // Create the order in our backend
      const orderResponse = await fetch(
        'https://bluedovecreation-f12655b62b2c.herokuapp.com/api/orders', // this is heroku
        // 'http://localhost:8000/api/orders',
        {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(orderDetails),
      });

      if (!orderResponse.ok) {
        throw new Error(`Error creating order: ${orderResponse.statusText}`);
      }

      const orderData = await orderResponse.json();
      console.log("Order created:", orderData.orderId, orderData.imageUrl);

      // Now proceed to create a Stripe checkout session
      setOrderId(orderData.orderId);
      await startCheckout(orderData.orderId);
    } catch (error) {
      console.error("Error submitting order:", error);
      alert("Error placing order. Please try again.");
    }
  };

  return (
    <div className='ai-image-generator'>

      <header className="logo-header">
        <img 
          src={bluedove} 
          alt="Company Logo" 
          className="logo-image" 
        />
      </header>



      {page === 'initial' && (
          <>
          <div className="examples-section">
            <div className="section">
              <h2>Get Inspiration and Start Creating</h2>

              <div className="design-row">

                <div className="example">
                  <img src={sampleDesign8} alt="Superman logo" />
                  <button className="custom-button" onClick={() => setPage('hasBoth')}>
                    Design has images and text
                  </button>
                </div>
                <div className="example">
                  {/* <div className="example-description">
                    <strong>Design Type:</strong> A typography design
                    <br />
                    <strong>Text:</strong> Stay Positive
                    <br />
                    <strong>Font:</strong> bold sans-serif
                    <br />
                    <strong>Text Color:</strong> black
                    <br />
                    <strong>Background Color:</strong> white
                  </div> */}
                  <img src={sampleDesign7} alt="Sample Design: Beach Sunset" />
                  <button className="custom-button" onClick={() => setPage('hasImage')}>
                    Design only has images
                  </button>
                </div>
                <div className="example">
                  <img src={sampleDesign5} alt="Sample Design: Love" />
                  <button className="custom-button" onClick={() => setPage('hasText')}>
                    Design only has text
                  </button>
                </div>

                </div>
                
              </div>
            </div>
          </>
      )}

      {page === 'describe' && (
        <div id="describe-section">
            <button className="back-btn" onClick={goBack}>
                ← Back
            </button>
            <h2>Create Your Design</h2>
            <div> 
               {/* <label htmlFor="subject">Describe your design</label> */}
                <textarea id="description-textarea" value={inputValue} onChange={(e) => setInputValue(e.target.value)} placeholder='Describe Your Design' rows="4"/>
                {/* <div className="generate-btn" onClick={generateImage}>Generate an image</div>  */}
                <button className="generate-btn" onClick={generateImage} disabled={loading}>
                  {loading ? "Generating..." : "Generate an image"}
                </button> 
            </div>

            <div className="img-loading">
            <div className="loading">
                    <div className={loading?"loading-bar-full":"loading-bar"}></div>
                    <div className={loading?"loading-text":"display-none"}>Loading...</div>
                </div>
                <div className="image">
                  <img src={image_url==="/"?background3:image_url} alt="Generated Design"/>
                </div>
            </div>
            {/* {generatedImage && (<div className="save-btn" onClick={saveImage}>Select the image</div> )} */}
            {generatedImage && (
              <>
                <div className="button-group">
                  <button className="save-btn" onClick={saveImage}>
                    Select the image
                  </button>
                  <button className="remove-bg-btn"onClick={removeBackground}>
                    Remove Background
                  </button>
                  <div className="feedback-option">
                    <p>If you don't like the image, describe what you don't like:</p>
                    <textarea
                      value={feedback}
                      onChange={(e) => setFeedback(e.target.value)}
                      placeholder="Describe what you don't like about the image"
                      rows="4"
                    />
                    <button className="generate-btn" onClick={handleFeedback}>
                      Generate a new image
                    </button>
                  </div>
                </div>
              </>
            )}

        </div>
      )}

      {/* New pages for each option */}
      {page === 'hasBoth' && (
        <>
          <button className="back-btn" onClick={goBack}>
              ← Back
          </button>

        <div className="design-container">
        <div className="design-inputs">

          <div className="prompt-generator">
            <h2>Create Your Design</h2>
            <div className="tag-container">
              {/* <div className="tag-input-select">
              <label htmlFor="subject">Design type</label>
              <select value={imageType} onChange={(e) => setImageType(e.target.value)}>
                  <option value="">Select...</option>
                  <option value="An image of">An image of</option>
                  <option value="A sketch of">A sketch of</option>
                  <option value="A photograph of">A photograph of</option>
                  <option value="A 3D rendering of">A 3D rendering of</option>
                  <option value="An illustration of">An illustration of</option>
                  <option value="A drawing of">A drawing of</option>
                  <option value="A picture of">A picture of</option>
                  <option value="A cubist painting of">A cubist painting of</option>
              </select>
              </div> */}

              <div className="tag-input">
                <label htmlFor="subject">Subject</label>
                <input 
                    type="text" 
                    placeholder="ex) a cat, a president, etc" 
                    value={subject} 
                    onChange={(e) => { setSubject(e.target.value); updatePrompt(); }} 
                />
              </div>
              <div className="tag-input">
                <label htmlFor="details">Details</label>
                <input 
                    type="text" 
                    placeholder="ex) with bright blue eyes, wearing a suit, etc" 
                    value={details} 
                    onChange={(e) => { setDetails(e.target.value); updatePrompt(); }}
                />
              </div>
              <div className="tag-input-text">
                <label htmlFor="text">Text</label> 
                <input 
                    type="text" 
                    placeholder="ex) I am happy, God loves you, etc" 
                    value={text} 
                    onChange={(e) => { setText(e.target.value); updatePrompt(); }}
                />
              </div>
                {/* <div className="tag-container-inline"> */}
              <div className="tag-input">
                <label htmlFor="textposition">Text position</label> 
                <input 
                    type="text" 
                    placeholder="ex) centered, below the logo, etc" 
                    value={textposition} 
                    onChange={(e) => { setTextPosition(e.target.value); updatePrompt(); }} 
                />
              </div>
              {/* <div className="tag-input">
                <label htmlFor="font">Font</label> 
                <input 
                    type="text" 
                    placeholder="ex) bold arial, italic roboto, etc" 
                    value={font} 
                    onChange={(e) => { setFont(e.target.value); updatePrompt(); }} 
                />
              </div> */}
              <div className="tag-input">
                  <label htmlFor="font">Font</label> 
                  <Select
                    id="font"
                    className="select-font"
                    value={fontOptions.find(option => option.value === font)}
                    onChange={handleFontChange}
                    options={fontOptions}
                    placeholder="Select a font..."
                    components={{ Option: CustomOption }} // Integrate CustomOption
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        maxWidth: '400px',
                        margin: '0 auto 20px auto',
                        borderRadius: '8px',
                        borderColor: '#ccc',
                        boxShadow: 'none',
                        minHeight: '48px', // Match the height of other inputs
                        height: '48px',
                        '&:hover': {
                          borderColor: '#999',
                        },
                      }),
                      valueContainer: (provided) => ({
                        ...provided,
                        height: '48px',
                        padding: '0 20px',
                      }),
                      input: (provided) => ({
                        ...provided,
                        margin: '0px',
                      }),
                      indicatorsContainer: (provided) => ({
                        ...provided,
                        height: '48px',
                      }),
                      placeholder: (provided) => ({
                        ...provided,
                        fontSize: '1rem',
                        color: '#999',
                      }),
                      singleValue: (provided, state) => ({
                        ...provided,
                        fontFamily: state.data.fontFamily,
                        fontSize: '1rem',
                        color: '#333',
                      }),
                      dropdownIndicator: (provided) => ({
                        ...provided,
                        padding: '0 8px',
                      }),
                      clearIndicator: (provided) => ({
                        ...provided,
                        padding: '0 8px',
                      }),
                      menu: (provided) => ({
                        ...provided,
                        fontFamily: 'Arial, sans-serif', // Default font for the menu
                      }),
                      option: (provided, state) => ({
                        ...provided,
                        fontFamily: state.data.fontFamily,
                        fontSize: '1rem',
                        backgroundColor: state.isFocused ? '#f0f0f0' : '#fff',
                        color: '#333',
                        padding: '12px 20px',
                      }),
                    }}
                  />
  
                  {/* </select> */}
              </div>
              <div className="tag-input">
                <label htmlFor="text color">Text color</label> 
                <input 
                    type="text" 
                    placeholder="ex) black, blue, red, etc" 
                    value={fontColor} 
                    onChange={(e) => { setFontColor(e.target.value); updatePrompt(); }}
                />
              </div>
              <div className="tag-input">
                <label htmlFor="background color">Background Color</label> 
                <input 
                    type="text" 
                    placeholder="ex) white, grey, black, etc" 
                    value={backgroundColor} 
                    onChange={(e) => { setbackgroundColor(e.target.value); updatePrompt(); }}
                />
              </div>
              <div className="tag-input-additional">
                <label htmlFor="additional description">Additional Description</label> 
                <input 
                    type="text" 
                    placeholder="Addtional description" 
                    value={additional} 
                    onChange={(e) => { setAdditional(e.target.value); updatePrompt(); }}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="design-preview">
          <div className="prompt-display">
            <textarea value={prompt} readOnly rows="4" />
          </div>
          <button className="generate-btn" onClick={generateImage} disabled={loading}>
            {loading ? "Generating..." : "Generate an image"}
          </button> 
          <div className="img-loading">
            <div className="loading">
                <div className={loading?"loading-bar-full":"loading-bar"}></div>
                <div className={loading?"loading-text":"display-none"}>Loading...</div>
            </div>
            <div className="image">
              <img src={image_url==="/"?background3:image_url} alt="Generated Design"/>
            </div>
          </div>

          {generatedImage && (
              // <>
            <div className="button-group">
              <button className="save-btn" onClick={saveImage}>
                Select the image
              </button>
              <button className="remove-bg-btn"onClick={removeBackground}>
                Remove Background
              </button>
              <div className="feedback-option">
                <p>If you don't like the image, describe what you don't like:</p>
                <textarea
                  value={feedback}
                  onChange={(e) => setFeedback(e.target.value)}
                  placeholder="Describe what you don't like about the image"
                  rows="4"
                />
                <button className="generate-btn" onClick={handleFeedback}>
                  Generate a new image
                </button>
              </div>
            </div>
              // </>
            )}
        </div>    
        </div>  
        </>
      )}

      {page === 'hasImage' && (
        <>
          <button className="back-btn" onClick={goBack}>
              ← Back
          </button>

          <div className="design-container">
          <div className="design-inputs">  

          <div className="prompt-generator">
          <h2>Create Your Design</h2>
            <div className="tag-container">
              {/* <div className="tag-input-select">
              <label htmlFor="subject">Design type</label>
              <select value={imageType} onChange={(e) => setImageType(e.target.value)}>
                  <option value="">Select...</option>
                  <option value="An image of">An image of</option>
                  <option value="A sketch of">A sketch of</option>
                  <option value="A photograph of">A photograph of</option>
                  <option value="A 3D rendering of">A 3D rendering of</option>
                  <option value="An illustration of">An illustration of</option>
                  <option value="A drawing of">A drawing of</option>
                  <option value="A picture of">A picture of</option>
                  <option value="A cubist painting of">A cubist painting of</option>
              </select>
            </div> */}

            <div className="tag-input">
            <label htmlFor="subject">Subject</label> 
                <input 
                    type="text" 
                    placeholder="ex) a cat, a president, etc" 
                    value={subject} 
                    onChange={(e) => { setSubject(e.target.value); updatePrompt(); }} 
                />
            </div>
            <div className="tag-input">
            <label htmlFor="details">Details</label> 
                <input 
                    type="text" 
                    placeholder="ex) with bright blue eyes, wearing a suit, etc" 
                    value={details} 
                    onChange={(e) => { setDetails(e.target.value); updatePrompt(); }}
                />
            </div>
            <div className="tag-input">
            <label htmlFor="background color">Background Color</label> 
                    <input 
                        type="text" 
                        placeholder="ex) white, grey, black, etc" 
                        value={backgroundColor} 
                        onChange={(e) => { setbackgroundColor(e.target.value); updatePrompt(); }}
                    />
                </div>
            <div className="tag-input-additional">
            <label htmlFor="additional description">Additional Description</label> 
                    <input 
                        type="text" 
                        placeholder="Addtional description" 
                        value={additional} 
                        onChange={(e) => { setAdditional(e.target.value); updatePrompt(); }}
                    />
                </div>
              </div>
          </div>
          </div>
      
          {/* <button className="prompt-ubtn" onClick={handleGeneratePrompt}>Generate Prompt</button> */}
          <div className="design-preview">

          <div className="prompt-display">
                <textarea value={prompt} readOnly rows="4" />
          </div>
          <button className="generate-btn" onClick={generateImage} disabled={loading}>
            {loading ? "Generating..." : "Generate an image"}
          </button> 
          <div className="img-loading">
          <div className="loading">
              <div className={loading?"loading-bar-full":"loading-bar"}></div>
              <div className={loading?"loading-text":"display-none"}>Loading...</div>
          </div>
              <div className="image">
                <img src={image_url==="/"?background3:image_url} alt="Generated Design"/>
              </div>
          </div>

          {/* {generatedImage && (<div className="save-btn" onClick={saveImage}>Select the image</div> )}   */}
          {generatedImage && (
              // <>
                <div className="button-group">
                  <button className="save-btn" onClick={saveImage}>
                    Select the image
                  </button>
                  <button className="remove-bg-btn"onClick={removeBackground}>
                    Remove Background
                  </button>
                  <div className="feedback-option">
                    <p>If you don't like the image, describe what you don't like:</p>
                    <textarea
                      value={feedback}
                      onChange={(e) => setFeedback(e.target.value)}
                      placeholder="Describe what you don't like about the image"
                      rows="4"
                    />
                    <button className="generate-btn" onClick={handleFeedback}>
                      Generate a new image
                    </button>
                  </div>
                </div>
              // </>
            )}
        </div>    
        </div>  
        </>
      )}

      {page === 'hasText' && (
        <>
          <button className="back-btn" onClick={goBack}>
              ← Back
          </button>

          <div className="design-container">
          <div className="design-inputs">

          <div className="prompt-generator">
          <h2>Create Your Design</h2>
            <div className="tag-container">
                {/* <div className="tag-input-select">
                <label htmlFor="subject">Design type</label>
                    <select value={imageType} onChange={(e) => setImageType(e.target.value)}>
                        <option value="">Select...</option>
                        <option value="A typography">A typography </option>
                        <option value="An illustration">An illustration </option>
                        <option value="A typography design">A typography design </option>
                        <option value="A typography illustration">A typography illustration </option>
                    </select>
                </div> */}
                {/* <span> of the text " </span> Fixed text */}
                <div className="tag-input-text">
                <label htmlFor="text">Text</label> 
                    <input 
                        type="text" 
                        placeholder="ex) I am happy, God loves you, etc" 
                        value={text} 
                        onChange={(e) => { setText(e.target.value); updatePrompt(); }}
                    />
                </div>
                {/* <div className="tag-container-inline"> */}
                <div className="tag-input">
                {/* <label htmlFor="font">Font</label> 
                    <input 
                        type="text" 
                        placeholder="ex) bold arial, italic roboto, etc" 
                        value={font} 
                        onChange={(e) => { setFont(e.target.value); updatePrompt(); }} 
                    /> */}
                    <label htmlFor="font">Font</label> 
                  <Select
                    id="font"
                    className="select-font"
                    value={fontOptions.find(option => option.value === font)}
                    onChange={handleFontChange}
                    options={fontOptions}
                    placeholder="Select a font..."
                    components={{ Option: CustomOption }} // Integrate CustomOption
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        maxWidth: '400px',
                        margin: '0 auto 20px auto',
                        borderRadius: '8px',
                        borderColor: '#ccc',
                        boxShadow: 'none',
                        minHeight: '48px', // Match the height of other inputs
                        height: '48px',
                        '&:hover': {
                          borderColor: '#999',
                        },
                      }),
                      valueContainer: (provided) => ({
                        ...provided,
                        height: '48px',
                        padding: '0 20px',
                      }),
                      input: (provided) => ({
                        ...provided,
                        margin: '0px',
                      }),
                      indicatorsContainer: (provided) => ({
                        ...provided,
                        height: '48px',
                      }),
                      placeholder: (provided) => ({
                        ...provided,
                        fontSize: '1rem',
                        color: '#999',
                      }),
                      singleValue: (provided, state) => ({
                        ...provided,
                        fontFamily: state.data.fontFamily,
                        fontSize: '1rem',
                        color: '#333',
                      }),
                      dropdownIndicator: (provided) => ({
                        ...provided,
                        padding: '0 8px',
                      }),
                      clearIndicator: (provided) => ({
                        ...provided,
                        padding: '0 8px',
                      }),
                      menu: (provided) => ({
                        ...provided,
                        fontFamily: 'Arial, sans-serif', // Default font for the menu
                      }),
                      option: (provided, state) => ({
                        ...provided,
                        fontFamily: state.data.fontFamily,
                        fontSize: '1rem',
                        backgroundColor: state.isFocused ? '#f0f0f0' : '#fff',
                        color: '#333',
                        padding: '12px 20px',
                      }),
                    }}
                    />
                </div>
                <div className="tag-input">
                <label htmlFor="text color">Text Color</label> 
                    <input 
                        type="text" 
                        placeholder="ex) black, blue, red, etc" 
                        value={fontColor} 
                        onChange={(e) => { setFontColor(e.target.value); updatePrompt(); }}
                    />
                </div>
                <div className="tag-input">
                <label htmlFor="background color">Background Color</label> 
                    <input 
                        type="text" 
                        placeholder="Background Color" 
                        value={backgroundColor} 
                        onChange={(e) => { setbackgroundColor(e.target.value); updatePrompt(); }}
                    />
                </div>
                <div className="tag-input-additional">
                <label htmlFor="additional description">Additional Description</label> 
                    <input 
                        type="text" 
                        placeholder="Addtional description" 
                        value={additional} 
                        onChange={(e) => { setAdditional(e.target.value); updatePrompt(); }}
                    />
                </div>
            </div>
            </div>
        </div>

            {/* <div className="generate-btn" onClick={generateImage}>Generate an image</div>  */}
           
        
        <div className="design-preview">
          <div className="prompt-display">
                <textarea value={prompt} readOnly rows="4" />
            </div>

          {/* <button className="prompt-ubtn" onClick={handleGeneratePrompt}>Generate Prompt</button> */}

          {/* <div> 
              <div className="generate-btn" onClick={generateImage}>Generate an image</div> 
          </div> */}
          <button className="generate-btn" onClick={generateImage} disabled={loading}>
            {loading ? "Generating..." : "Generate an image"}
          </button> 

          <div className="img-loading">
          <div className="loading">
              <div className={loading?"loading-bar-full":"loading-bar"}></div>
              <div className={loading?"loading-text":"display-none"}>Loading...</div>
            </div>
            
              <div className="image">
                <img src={image_url==="/"?background3:image_url} alt="Generated Design"/>
              </div>
          </div>

          {/* {generatedImage && (<div className="save-btn" onClick={saveImage}>Select the image</div> )}   */}
          {generatedImage && (
              // <>
                <div className="button-group">
                  <button className="save-btn" onClick={saveImage}>
                    Select the image
                  </button>
                  <button className="remove-bg-btn"onClick={removeBackground}>
                    Remove Background
                  </button>
                  <div className="feedback-option">
                    <p>If you don't like the image, describe what you don't like:</p>
                    <textarea
                      value={feedback}
                      onChange={(e) => setFeedback(e.target.value)}
                      placeholder="Describe what you don't like about the image"
                      rows="4"
                    />
                    <button className="generate-btn" onClick={handleFeedback}>
                      Generate a new image
                    </button>
                  </div>
                </div>
            )}
            </div>    
        </div>  
        </>
      )}

      {page === 'selectColor' && finalImage && (
        <>
        <button className="back-btn" onClick={goBack}>
          ← Back
        </button>
        {/* <div className="next-page"> */}
          <div className="color-picker">
            <div className="select-tshirt-header">Select T-shirt Color:</div>
            <div className="color-options">
              {colorOptions.map((option) => (
                  <button key={option.name} style={{ backgroundColor: option.color }} className="color-option" onClick={() => setTshirtColor(option.color)} aria-label={option.name}>
                  </button>
              ))}
            </div>
          </div>
      
          {tshirtColor && (
            <div className="tshirt-preview-container">
              <div className="tshirt-preview" 
              style={{ backgroundImage: `url(${tshirtTemplates[tshirtColor]})` }}>
                <img src={finalImage} alt="T-shirt design" className="tshirt-image"/>
              </div>

            {/* Size Selection */}
            <div className="size-picker">
              <div className="select-size-header">Select T-shirt Size:</div>
              <div className="size-options">
                {sizeOptions.map((size) => (
                  <button
                    key={size}
                    className={`size-option ${tshirtSize === size ? "selected" : ""}`}
                    onClick={() => setTshirtSize(size)}
                  >
                    {size}
                  </button>
                ))}
              </div>
            </div>  

              <div className="order-btn-container">
                <button className="order-btn" onClick={orderTshirt}>Order</button>
              </div>  
            </div>
            )
          }
     </>
        // </div>
      )}

{page === 'orderForm' && (
  <>
    <button className="back-btn" onClick={goBack}>← Back</button>
    <h2>Enter Your Order Details</h2>
    <div className="order-form">
      {errorMessage && <p style={{ color: 'red', textAlign: 'center' }}>{errorMessage}</p>}
      
      {/* Name, email, phone... */}
      <div className="form-group">
        <label htmlFor="name">Name:</label>
        <input 
          id="name" 
          type="text" 
          value={name} 
          onChange={(e)=>setName(e.target.value)} 
          placeholder="Your full name"/>
      </div>
      <div className="form-group">
        <label htmlFor="email">Email:</label>
        <input 
          id="email" 
          type="email" 
          value={email} 
          onChange={(e)=>setEmail(e.target.value)} 
          placeholder="Your email"/>
      </div>
      <div className="form-group">
        <label htmlFor="phone">Phone:</label>
        <input 
          id="phone" 
          type="text" 
          value={phone} 
          onChange={(e)=>setPhone(e.target.value)} 
          placeholder="Your phone number"/>
      </div>
      <div className="form-group">
        <label htmlFor="street">Street:</label>
        <input
          id="street"
          type="text"
          value={street}
          onChange={(e) => setStreet(e.target.value)}
          placeholder="1234 Main St"
          required
        />
      </div>
      <div className="form-group">
        <label htmlFor="city">City:</label>
        <input
          id="city"
          type="text"
          value={city}
          onChange={(e) => setCity(e.target.value)}
          placeholder="Los Angeles"
          required
        />
      </div>
      <div className="form-group">
        <label htmlFor="state">State:</label>
        <input
          id="state"
          type="text"
          value={state}
          onChange={(e) => setState(e.target.value)}
          placeholder="CA"
          required
        />
      </div>
      <div className="form-group">
        <label htmlFor="zip">Zip:</label>
        <input
          id="zip"
          type="text"
          value={zip}
          onChange={(e) => setZip(e.target.value)}
          placeholder="90001"
          required
        />
      </div>
      
      <div className="order-btn-container">
        <button className="order-btn" onClick={submitOrder}>
          Place Order and Proceed to Payment
        </button>
      </div>
    </div>
  </>
)}


    </div>
  )
}
export default ImageGenerator
