// src/Components/PaymentSuccess/PaymentSuccess.jsx
import React from 'react';
import './PaymentSuccess.css'; // Import the CSS file

const PaymentSuccess = () => {
  return (
    <div className="payment-success-container">
      <div className="success-icon">
        <svg width="64" height="64" fill="none" stroke="currentColor" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" ><path d="M48 20L27 41l-11-11"></path><circle cx="32" cy="32" r="30"/></svg>
      </div>
      <h1>Payment Successful!</h1>
      <p>Thank you for your order. Your payment has been processed successfully.</p>
      <p>We’re getting started on your custom t-shirt right now. Expect delivery in 5-7 business days.</p>
      {/* <p>Have questions? <a href="/contact">Contact our support team.</a></p> */}
      <div className="success-actions">
        <a href="/">Go back to Home</a>
      </div>
    </div>
  );
};

export default PaymentSuccess;
